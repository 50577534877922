const arStyles = ['classic', 'modern'];

function changeStyle(aButton, elMain, styleButtons) {
	console.log('===> changeStyle', {styleButtons, aButton});
	for (let button2 of styleButtons) {
		if (button2 === aButton) {
			button2.classList.add('selected');
		} else {
			button2.classList.remove('selected');
		}
	}
	for (let style of arStyles) {
		if (aButton.dataset.style === style) {
			elMain.classList.add(style);
		} else {
			elMain.classList.remove(style);
		}
	}
}

function print() {
	window.print();
	return false;
}

window.addEventListener("load", () => {
	const elMain = document.querySelector("main");
	const styleButtons = document.querySelectorAll("a[data-button=style]");
	for (let button of styleButtons) {
		button.addEventListener("click", (event) => {
			event.preventDefault();
			changeStyle(button, elMain, styleButtons);
		});
	}
	document.querySelector("a[data-button=print]").addEventListener("click", () => print());
});
